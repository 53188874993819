<template>
  <v-container pa-0 ma-0 fluid class="home">
    <v-layout row>
      <v-flex>
        <v-card flat tile dark class="hero-home-slider">
          <!-- Use .home-single-slide to hide carousel pagination -->
          <v-carousel height="auto" class="elevation-0">
            <!-- Slide #0 - AU Book -->
            <v-carousel-item>
              <v-img
                src="https://cdn.anzcro.com.au/wp-content/uploads/2024/07/hero-au-book-north-america-24-scaled.jpg"
                gradient="to bottom, rgba(22,22,22,0.05), rgba(22,22,22,0.05) 15%, rgba(22,22,22,0.05) 65%"
                class="home-hero"
              >
                <v-container fill-height>
                  <v-layout dark column fill-height justify-space-between pt-5>
                    <v-flex text-xs-center text-md-left class="home-hero-cta">
                      <v-layout row align-center fill-height class="fill-height-fix-ios">
                        <v-flex>
                          <h3 class="title mb-2 kiwiGreen">30<sup>th</sup> Anniversary Edition</h3>
                          <h1 class="white--text display-1 font-weight-bold mb-2">
                            The Australia Book<br/>2024–2025
                          </h1>
                          <h3 class="white--text title mb-3">The biggest Australia Book yet.</h3>
                          <v-btn
                            large
                            class="ml-0 mt-2 mb-2 elevation-0"
                            dark
                            :color="kiwiGreen"
                            target="_blank"
                            href="https://issuu.com/holidayexperts/docs/theausb24-25_northamerica_e-brochure?fr=xKAE9_zU1NQ"
                          >View Brochure</v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
                <div class="hero-caption hidden-xs-only hidden-sm-and-up">
                  <small><!--Uluru-Kata Tjuta National Park, NT--></small>
                </div>
              </v-img>
            </v-carousel-item>
            <!-- Slide #0 - NZ Book -->
            <v-carousel-item>
              <v-img
                src="https://cdn.anzcro.com.au/wp-content/uploads/2024/07/hero-nz-book-north-america-24-scaled.jpg"
                gradient="to bottom, rgba(22,22,22,0.05), rgba(22,22,22,0.05) 15%, rgba(22,22,22,0.05) 65%"
                class="home-hero"
              >
                <v-container fill-height>
                  <v-layout dark column fill-height justify-space-between pt-5>
                    <v-flex text-xs-center text-md-left class="home-hero-cta">
                      <v-layout row align-center fill-height class="fill-height-fix-ios">
                        <v-flex>
                          <h3 class="title mb-2 kiwiGreen">30<sup>th</sup> Anniversary Edition</h3>
                          <h1 class="white--text display-1 font-weight-bold mb-2">
                            The New Zealand<br/>Book 2024–2025
                          </h1>
                          <h3 class="white--text title mb-3">The most comprehensive NZ Book yet.</h3>
                          <v-btn
                            large
                            class="ml-0 mt-2 mb-2 elevation-0"
                            dark
                            :color="kiwiGreen"
                            target="_blank"
                            href="https://issuu.com/holidayexperts/docs/na_nzbk2024-25_e-brochure?fr=xKAE9_zU1NQ"
                          >View Brochure</v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
                <div class="hero-caption hidden-xs-only hidden-sm-and-up">
                  <small><!--Uluru-Kata Tjuta National Park, NT--></small>
                </div>
              </v-img>
            </v-carousel-item>
            <!-- Slide #0 - Promo -->
            
            <!-- Slide #0 - AU & NZ Vacations -->
            <v-carousel-item>
              <v-img
                src="https://cdn.anzcro.com.au/wp-content/uploads/2024/04/hero-au-nz-vacations-brochure-2024-scaled.jpg"
                gradient="to bottom, rgba(22,22,22,0.05), rgba(22,22,22,0.05) 15%, rgba(22,22,22,0.05) 65%"
                class="home-hero"
              >
                <v-container fill-height>
                  <v-layout dark column fill-height justify-space-between pt-5>
                    <v-flex text-xs-center text-md-left class="home-hero-cta">
                      <v-layout row align-center fill-height class="fill-height-fix-ios">
                        <v-flex>
                          <h1 class="white--text display-1 font-weight-bold mb-3">
                            Discover our <br/>most popular <br/>vacations
                          </h1>
                          <h3 class="white--text title mb-3">Explore the best of Australia and New Zealand with our customizable itineraries.</h3>
                          <!--<small class="d-block mb-4">View online or order your copy at <b>BROCHURENET</b></small>-->
                          <v-btn
                            large
                            class="ml-0 mt-2 mb-2 elevation-0"
                            dark
                            :color="kiwiGreen"
                            target="_blank"
                            href="https://issuu.com/holidayexperts/docs/issuu_iconic_vacations_24-25?fr=xKAE9_zU1NQ"
                          >View Brochure</v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
                <div class="hero-caption hidden-xs-only hidden-sm-and-up">
                  <small><!--Uluru-Kata Tjuta National Park, NT--></small>
                </div>
              </v-img>
            </v-carousel-item>
            <!-- Slide #1 - Great Rail Journeys -->
            <!--<v-carousel-item>
              <v-img
                src="https://cdn.anzcro.com.au/wp-content/uploads/2023/07/hero-tranzalpine-scaled.jpg"
                gradient="to bottom, rgba(22,22,22,0.6), rgba(22,22,22,0.55) 15%, rgba(22,22,22,0.5) 65%"
                class="home-hero"
              >
                <v-container fill-height>
                  <v-layout dark column fill-height justify-space-between pt-5>
                    <v-flex text-xs-center text-md-left class="home-hero-cta">
                      <v-layout row align-center fill-height class="fill-height-fix-ios">
                        <v-flex>
                          <h3 class="title mb-2">8 Days from $1,699* pp</h3>
                          <h1 class="white--text display-1 font-weight-bold mb-2">
                            Great Rail Journeys <br/>of New Zealand
                          </h1>
                          <h3 class="white--text title mb-1">Discover a collection of our most popular self-guided New Zealand Rail packages.</h3>
                          <v-btn
                            large
                            class="ml-0 mt-3 mb-2 elevation-0"
                            dark
                            :color="kiwiGreen"
                            target="_blank"
                            href="https://issuu.com/holidayexperts/docs/rail_e-brochure_2024_issuu?fr=xKAE9_zU1NQ"
                          >View Brochure</v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
                <div class="hero-caption hidden-xs-only">
                  <small>TranzAlpine Train Journey - Photo: KiwiRail</small>
                </div>
              </v-img>
            </v-carousel-item>-->
            <!-- Slide #1 - Luxury Brochure -->
            <!--<v-carousel-item>
              <v-img
                src="https://cdn.anzcro.com.au/wp-content/uploads/2023/09/hero-luxury-brochure-23-scaled.jpg"
                gradient="to bottom, rgba(22,22,22,0.25), rgba(22,22,22,0.15) 15%, rgba(22,22,22,0.1) 65%"
                class="home-hero"
              >
                <v-container fill-height>
                  <v-layout dark column fill-height justify-space-between pt-5>
                    <v-flex text-xs-center text-md-left class="home-hero-cta">
                      <v-layout row align-center fill-height class="fill-height-fix-ios">
                        <v-flex>
                          <h1 class="white--text display-1 font-weight-bold mb-2">
                            Travel beyond the ordinary
                          </h1>                          
                          <h3 class="white--text title mb-1">Discover a collection of Australia and New Zealand’s most spectacular luxury properties.</h3>
                          <v-btn
                            large
                            class="ml-0 mt-3 mb-2 elevation-0"
                            dark
                            :color="kiwiGreen"
                            target="_blank"
                            href="https://issuu.com/holidayexperts/docs/anzcro_luxury_23-24_aud_issuu?fr=xKAE9_zU1NQ"
                          >View Brochure</v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
                <div class="hero-caption hidden-xs-only hidden-sm-and-up">
                  <small>Brochure Cover: Funyaks, Dart River Adventures</small>
                </div>
              </v-img>
            </v-carousel-item>-->
            <!-- Slide #4 - ANZCRO Connect -->
            <v-carousel-item>
              <v-img
                src="https://cdn.anzcro.com.au/wp-content/uploads/2021/05/home-hero-au-fiordland-scaled.jpg"
                gradient="to bottom, rgba(22,22,22,1), rgba(22,22,22,.35) 15%, rgba(22,22,22,0.1) 65%"
                class="home-hero"
              >
                <v-container fill-height>
                  <v-layout dark column fill-height justify-space-between pt-5>
                    <v-flex text-xs-center text-md-left class="home-hero-cta">
                      <v-layout row align-center fill-height class="fill-height-fix-ios">
                        <v-flex>
                          <h1 class="white--text display-1 font-weight-bold mb-3">
                            Create & manage
                            <br />your own bookings
                          </h1>
                          <h3 class="white--text title mb-4">Enjoy 24/7 Access To 40,000 Travel Products with our self-service booking platform ANZCRO Connect.</h3>
                          <v-btn
                            large
                            class="mt-2 mb-2 elevation-0 px-4"
                            color="white"
                            outline
                            target="_blank"
                            href="https://cdn.anzcro.com.au/wp-content/uploads/2023/08/Infograph_ANZCRO-CONNECT-FINAL.pdf"
                          >Learn More</v-btn>                          
                          <v-divider class="ma-0 pa-0 hidden-sm-and-up" style="opacity:0;"></v-divider>                          
                          <v-btn
                            large
                            class="ml-0 mt-2 mb-2 elevation-0"
                            dark
                            :color="kiwiGreen"
                            to="/register/"
                          >Register Now</v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
                <div class="hero-caption hidden-xs-only">
                  <small>Milford Sound, Fiordland - Photo: Matt Crawford</small>
                </div>
              </v-img>
            </v-carousel-item>
            <!-- Slide #1 - ANZCRO Connect -->
            <!--<v-carousel-item>
              <v-img
                src="https://cdn.anzcro.com.au/wp-content/uploads/2022/11/hero-split-nt-southern-alps-scaled.jpg"
                gradient="to bottom, rgba(22,22,22,1), rgba(22,22,22,.45) 15%, rgba(22,22,22,0.35) 65%"
                class="home-hero"
              >
                <v-container fill-height>
                  <v-layout dark column fill-height justify-space-between pt-5>
                    <v-flex text-xs-center text-md-left class="home-hero-cta">
                      <v-layout row align-center fill-height class="fill-height-fix-ios">
                        <v-flex>
                          <h1 class="white--text display-1 font-weight-bold mb-3">
                            Why Book
                            <br />With ANZCRO
                          </h1>
                          <h3 class="white--text title mb-4">The Australian and New Zealand<br/>Holiday Experts</h3>
                          <v-btn
                            large
                            class="ml-0 mt-2 mb-2 elevation-0"
                            dark
                            :color="kiwiGreen"
                            to="/about/"
                          >Learn More</v-btn>
                          <v-divider class="ma-0 pa-0 hidden-sm-and-up" style="opacity:0;"></v-divider>
                          <v-btn
                            large
                            class="mt-2 mb-2 elevation-0 px-4"
                            color="white"
                            outline
                            to="/contact/"
                          >Get in Touch</v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
                <div class="hero-caption hidden-xs-only">
                  <small>Northern Territory, AU / Southern Alps, NZ</small>
                </div>
              </v-img>
            </v-carousel-item>-->
            <!-- Slide #2 - Motorhome Deals -->
            <!--<v-carousel-item>
              <v-img
                src="https://cdn.anzcro.com.au/wp-content/uploads/2019/11/home-motorhome-beach-hero-bg.jpg"
                gradient="to bottom, rgba(22,22,22,1), rgba(22,22,22,.35) 15%, rgba(22,22,22,0.1) 65%"
                class="home-hero"
              >
                <v-container fill-height>
                  <v-layout dark column fill-height justify-space-between pt-5>
                    <v-flex text-xs-center text-md-left class="home-hero-cta">
                      <v-layout row align-center fill-height class="fill-height-fix-ios">
                        <v-flex>
                          <h1 class="white--text display-1 font-weight-bold mb-3">Exclusive<br/>Motorhome Deals</h1>
                          <h3 class="white--text title mb-4">Up to 25% Off</h3>
                          <v-btn
                            large
                            dark
                            class="ml-0 mt-4 mb-2 elevation-0"
                            :color="kiwiGreen"
                            to="/motorhome-deals/"
                          >Learn More</v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
                <div class="hero-caption hidden-xs-only">
                  <small>Caption</small>
                </div>
              </v-img>
            </v-carousel-item>-->
            <!-- Slide #3 - Sightseeing Deals -->
            <!--<v-carousel-item>
              <v-img
                src="https://cdn.anzcro.com.au/wp-content/uploads/2019/11/home-milford-cruise-hero-bg.jpg"
                gradient="to bottom, rgba(22,22,22,1), rgba(22,22,22,.35) 15%, rgba(22,22,22,0.1) 65%"
                class="home-hero"
              >
                <v-container fill-height>
                  <v-layout dark column fill-height justify-space-between pt-5>
                    <v-flex text-xs-center text-md-left class="home-hero-cta">
                      <v-layout row align-center fill-height class="fill-height-fix-ios">
                        <v-flex>
                          <h1 class="white--text display-1 font-weight-bold mb-3">Exclusive<br/>Sightseeing Deals</h1>
                          <h3 class="white--text title mb-4">25% Off Milford Coach & Nature Cruise</h3>
                          <v-btn
                            large
                            dark
                            class="ml-0 mt-4 mb-2 elevation-0"
                            :color="kiwiGreen"
                            to="/sightseeing-deals/"
                          >Learn More</v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
                <div class="hero-caption hidden-xs-only">
                  <small>Caption</small>
                </div>
              </v-img>
            </v-carousel-item>-->
          </v-carousel>
        </v-card>
      </v-flex>
    </v-layout>

    <!--<v-layout row>
      <v-flex text-xs-center>
        <v-card dark flat tile :color="kiwiGreen" class="pa-3">
          <v-layout row align-center>
            <v-flex>
              <v-icon size="18" class="mr-2">warning</v-icon><span>COVID-19 Travel Booking Information</span><v-btn
                small
                dark
                class="elevation-0 px-3 ml-3"
                :color="coalBlack"
                to="/covid19/"
                >Read Here</v-btn>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>-->

    <!--<v-layout row class="home-section-why">
      <v-flex>
        <v-card dark :color="kiwiGreen" flat tile class="transparent">
          <v-container text-xs-center>
            <v-layout row>
              <v-flex xs12 py-1>
                <h2 class="display-1 font-weight-medium">The New Zealand Holiday Experts</h2>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>-->

    <v-layout row class="home-section-cards">
      <v-flex>
        <v-card dark :color="coalBlack" flat tile class="transparent">
          <v-container grid-list-lg>
            <v-layout row wrap align-center>
              <v-flex xs12 lg3 text-xs-center>
                <v-img
                  src="https://cdn.anzcro.com.au/wp-content/uploads/2020/01/anzcro-connect-inverted.svg"
                  width="160"
                  class="my-2 d-inline-block"
                ></v-img>
                <v-btn
                  large
                  class="my-2 mx-0 elevation-0 hidden-md-and-down"
                  color="white"
                  outline
                  to="/register/"
                >Register</v-btn>
              </v-flex>
              <v-flex xs12 hidden-sm-and-up>
                <v-divider></v-divider>
              </v-flex>
              <v-flex xs12 lg9 class="home-hero-cards">
                <v-layout row wrap>
                  <v-flex xs12 sm6 md3 class="connect-icon-item">
                    <v-layout row wrap align-center>
                      <v-flex xs3 md12 text-xs-center>
                        <img
                          height="60"
                          :src="require('@/assets/img/icons/display/A01_Search_NZ_Inventory.svg')"
                        />
                      </v-flex>
                      <v-flex xs9 md12 px-2 text-md-center>
                        <span class="subheading font-weight-medium">
                          Large inventory of
                          <br />New Zealand and
                          <br />Australian products
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 hidden-sm-and-up>
                    <v-divider></v-divider>
                  </v-flex>
                  <v-flex xs12 sm6 md3 class="connect-icon-item">
                    <v-layout row wrap align-center>
                      <v-flex xs3 md12 text-xs-center>
                        <img
                          height="60"
                          :src="require('@/assets/img/icons/display/A02_Quicky_Itineraries.svg')"
                        />
                      </v-flex>
                      <v-flex xs9 md12 px-2 text-md-center>
                        <span class="subheading font-weight-medium">
                          Book multiple products
                          <br />in a single transaction
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 hidden-sm-and-up>
                    <v-divider></v-divider>
                  </v-flex>
                  <v-flex xs12 sm6 md3 class="connect-icon-item">
                    <v-layout row wrap align-center>
                      <v-flex xs3 md12 text-xs-center>
                        <img
                          height="60"
                          :src="require('@/assets/img/icons/display/A03_Easy_Quotes.svg')"
                        />
                      </v-flex>
                      <v-flex xs9 md12 px-2 text-md-center>
                        <span class="subheading font-weight-medium">
                          Easily manage
                          <br />quotes & bookings
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 hidden-sm-and-up>
                    <v-divider></v-divider>
                  </v-flex>
                  <v-flex xs12 sm6 md3 class="connect-icon-item">
                    <v-layout row wrap align-center>
                      <v-flex xs3 md12 text-xs-center>
                        <img
                          height="60"
                          :src="require('@/assets/img/icons/display/A04_Reports.svg')"
                        />
                      </v-flex>
                      <v-flex xs9 md12 px-2 text-md-center>
                        <span class="subheading font-weight-medium">
                          24/7 access to
                          <br />client documentation
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 text-xs-center mt-3 hidden-lg-and-up>
                <v-btn
                  large
                  class="ml-0 my-2 elevation-0"
                  dark
                  :color="kiwiGreen"
                  to="/register/"
                >Register</v-btn>
                <v-btn
                  large
                  class="my-2 mr-0 elevation-0 px-4"
                  color="white"
                  outline
                  to="/login/"
                >Login</v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>


    <!--
    <v-layout row class="home-section-about">
      <v-flex>
        <v-card flat tile>
          <v-container text-xs-center>
            <v-layout row wrap>
              <v-flex xs12 lg10 pt-5 offset-lg1>
                <h3 class="home-section-title green-on-white">
                  <span>About Anzcro</span>
                </h3>
                <span
                  class="about-quote headline d-block font-weight-bold mb-5"
                >We love helping Travel Agents provide their customers with extraordinary holidays in New Zealand.</span>
              </v-flex>
              <v-flex xs12>
                <p
                  class="text-xs-left mx-2"
                >For over 20 years our dedicated team has lived and breathed New Zealand - our vast knowledge and deep understanding of the destination is unparalleled. We are proud to give Travel Agents the edge when it comes to selling New Zealand holidays with our market-leading range of over 5,000 travel products provided by hundreds of New Zealand operators.</p>
                <p
                  class="text-xs-left mx-2"
                >Whether your customers want to enjoy the ultimate freedom of a self-drive holiday, experience the thrill of skiing in a winter wonderland, or immerse themselves in the natural beauty on a guided walk, ANZCRO’s team of New Zealand specialists will ensure each itinerary is meticulously planned to cater for a variety of traveller’s needs.</p>
                <p
                  class="text-xs-left mx-2"
                >ANZCRO’s ‘boots-on-the-ground’ approach, and state-of-the-art technology, gives you 24/7 access to our expertise and the best New Zealand booking solution for your business. By working with us, you can rest assured your customers are in good hands.</p>
                <v-avatar :color="lightGrey" size="70" class="mt-4 mb-2">
                  <img
                    src="https://cdn.anzcro.com.au/wp-content/uploads/2019/11/nick-guthrey-avatar.png"
                    alt="Nick Guthrey, CEO"
                  />
                </v-avatar>
                <span class="d-block mb-5">Nick Guthrey, CEO</span>
                <v-btn
                  large
                  dark
                  to="/about/"
                  :color="headerGrey"
                  outline
                  class="mt-0 mx-auto elevation-0 mb-5"
                >Find out more about ANZCRO</v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
    -->

    <v-layout row class="home-section-product">
      <v-flex>
        <v-card dark :color="coalBlack" flat tile>
          <v-img
            src="https://cdn.anzcro.com.au/wp-content/uploads/2019/11/home-ruapehu-1920w.jpg"
            gradient="to bottom, rgba(48,48,47,.75), rgba(22,22,22,0.85)"
            class="home-product-bg"
          >
            <v-container text-xs-center fill-height>
              <v-layout row align-center>
                <v-flex xs12 sm10 md12 py-5 offset-sm1 offset-md0>
                  <h3 class="home-section-title white-on-dark">
                    <span>Our Product Range</span>
                  </h3>
                  <v-layout row wrap>
                    <v-flex xs6 sm4 md3 text-xs-center offset-md32 class="product-range-item">
                      <img
                        height="60"
                        :src="require('@/assets/img/icons/display/B01_Accommodation.svg')"
                      />
                      <span class="subheading d-block mt-2">Accommodation</span>
                    </v-flex>
                    <v-flex xs6 sm4 md3 text-xs-center class="product-range-item">
                      <img
                        height="60"
                        :src="require('@/assets/img/icons/display/B02_FIT_Packages.svg')"
                      />
                      <span class="subheading d-block mt-2">FIT Packages</span>
                    </v-flex>
                    <v-flex xs6 sm4 md3 text-xs-center class="product-range-item">
                      <img
                        height="60"
                        :src="require('@/assets/img/icons/display/B11_Flights.svg')"
                      />
                      <span class="subheading d-block mt-2">
                        Internal
                        <br />Domestic Flights
                      </span>
                    </v-flex>
                    <v-flex xs6 sm4 md3 text-xs-center class="product-range-item">
                      <img
                        height="60"
                        :src="require('@/assets/img/icons/display/B04_Rental_Vehicles.svg')"
                      />
                      <span class="subheading d-block mt-2">
                        Rental Cars
                        <br />& Campers
                      </span>
                    </v-flex>
                    <v-flex xs6 sm4 md3 text-xs-center class="product-range-item">
                      <img
                        height="60"
                        :src="require('@/assets/img/icons/display/B05_DayTours.svg')"
                      />
                      <span class="subheading d-block mt-2">
                        Day Tours &
                        <br />Activities
                      </span>
                    </v-flex>
                    <v-flex xs6 sm4 md3 text-xs-center class="product-range-item">
                      <img
                        height="60"
                        :src="require('@/assets/img/icons/display/B12_Diamond.svg')"
                      />
                      <span class="subheading d-block mt-2">
                        Luxury
                        <br />Holidays
                      </span>
                    </v-flex>
                    <v-flex xs6 sm4 md3 text-xs-center class="product-range-item">
                      <img
                        height="60"
                        :src="require('@/assets/img/icons/display/B07_Transfers.svg')"
                      />
                      <span class="subheading d-block mt-2">
                        Transfers &
                        <br />Intercity Travel
                      </span>
                    </v-flex>
                    <v-flex xs6 sm4 md3 offset-md32 text-xs-center class="product-range-item">
                      <img
                        height="60"
                        :src="require('@/assets/img/icons/display/B08_Walks_and_Hikes.svg')"
                      />
                      <span class="subheading d-block mt-2">Walks & Hikes</span>
                    </v-flex>
                    <v-flex xs6 sm4 md3 text-xs-center class="product-range-item">
                      <img
                        height="60"
                        :src="require('@/assets/img/icons/display/B09_Cruise_Shore_Excursions.svg')"
                      />
                      <span class="subheading d-block mt-2">
                        Cruise Shore
                        <br />Excursions
                      </span>
                    </v-flex>
                    <v-flex
                      xs6
                      sm4
                      md3
                      offset-sm4
                      offset-md0
                      text-xs-center
                      class="product-range-item"
                    >
                      <img
                        height="60"
                        :src="require('@/assets/img/icons/display/B10_Rail_Journeys.svg')"
                      />
                      <span class="subheading d-block mt-2">Rail Journeys</span>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-container>
          </v-img>
        </v-card>
      </v-flex>
    </v-layout>

    <v-layout row class="home-section-why">
      <v-flex>
        <v-card dark :color="kiwiGreen" flat tile class="transparent">
          <v-container text-xs-center>
            <v-layout row>
              <v-flex xs12 py-5>
                <h3 class="home-section-title white-on-green">
                  <span>Why Choose Anzcro</span>
                </h3>
                <!--<v-img :src="require('@/assets/img/home-hero-devices-green.png')" class="mx-auto"></v-img>-->
                <v-card class="elevation-10 py-0">
                  <v-responsive :aspect-ratio="16/9">
                    <!--<iframe
                      width="100%"
                      height="100%"
                      src="https://www.youtube.com/embed/QXhdxJfGn70"
                      frameborder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>-->
                    <div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/372023992?title=0&byline=0&portrait=0" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe></div>
                  </v-responsive>
                </v-card>
                <v-layout row wrap text-xs-left mt-5>
                  <v-flex xs12 sm6 md4 my-3 px-3>
                    <v-layout row>
                      <v-flex xs2 text-xs-right pr-3>
                        <v-avatar size="24" color="#5A992E" class="mt-2">
                          <v-icon size="18">check</v-icon>
                        </v-avatar>
                      </v-flex>
                      <v-flex xs10>
                        <span class="subheading font-weight-medium">
                          The largest inventory of New Zealand
                          <br />and Australian travel product
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6 md4 my-3 px-3>
                    <v-layout row>
                      <v-flex xs2 text-xs-right pr-3>
                        <v-avatar size="24" color="#5A992E" class="mt-2">
                          <v-icon size="18">check</v-icon>
                        </v-avatar>
                      </v-flex>
                      <v-flex xs10>
                        <span class="subheading font-weight-medium">
                          Directly contracted hotels
                          <br />with competitive rates
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6 md4 my-3 px-3>
                    <v-layout row>
                      <v-flex xs2 text-xs-right pr-3>
                        <v-avatar size="24" color="#5A992E" class="mt-2">
                          <v-icon size="18">check</v-icon>
                        </v-avatar>
                      </v-flex>
                      <v-flex xs10>
                        <span class="subheading font-weight-medium">
                          Pre-built holiday packages
                          <br />ready to book
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6 md4 my-3 px-3>
                    <v-layout row>
                      <v-flex xs2 text-xs-right pr-3>
                        <v-avatar size="24" color="#5A992E" class="mt-2">
                          <v-icon size="18">check</v-icon>
                        </v-avatar>
                      </v-flex>
                      <v-flex xs10>
                        <span class="subheading font-weight-medium">
                          Dynamic pricing with
                          <br />live availability
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6 md4 my-3 px-3>
                    <v-layout row>
                      <v-flex xs2 text-xs-right pr-3>
                        <v-avatar size="24" color="#5A992E" class="mt-2">
                          <v-icon size="18">check</v-icon>
                        </v-avatar>
                      </v-flex>
                      <v-flex xs10>
                        <span class="subheading font-weight-medium">
                          Quick turnaround for
                          <br />booking confirmations
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6 md4 my-3 px-3>
                    <v-layout row>
                      <v-flex xs2 text-xs-right pr-3>
                        <v-avatar size="24" color="#5A992E" class="mt-2">
                          <v-icon size="18">check</v-icon>
                        </v-avatar>
                      </v-flex>
                      <v-flex xs10>
                        <span class="subheading font-weight-medium">
                          Automation enables live
                          <br />booking notification alerts
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6 md4 my-3 px-3>
                    <v-layout row>
                      <v-flex xs2 text-xs-right pr-3>
                        <v-avatar size="24" color="#5A992E" class="mt-2">
                          <v-icon size="18">check</v-icon>
                        </v-avatar>
                      </v-flex>
                      <v-flex xs10>
                        <span class="subheading font-weight-medium">
                          24/7 access to quote, amend
                          <br />& book through online system
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6 md4 my-3 px-3>
                    <v-layout row>
                      <v-flex xs2 text-xs-right pr-3>
                        <v-avatar size="24" color="#5A992E" class="mt-2">
                          <v-icon size="18">check</v-icon>
                        </v-avatar>
                      </v-flex>
                      <v-flex xs10>
                        <span class="subheading font-weight-medium">
                          Expert reservations support
                          via live chat, email & phone
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>

    <v-layout row class="home-reservations">
      <v-flex>
        <v-card flat tile>
          <v-layout row wrap align-space-around>
            <v-flex xs12 md6 class="home-reservations-image">
              <v-img
                src="https://cdn.anzcro.com.au/wp-content/uploads/2019/11/anzcro-call-center-temp.jpg"
                height="100%"
                position="top left"
              ></v-img>
            </v-flex>
            <v-flex xs12 md6 class="home-reservations-content" align-self-center>
              <v-layout row wrap my-5>
                <v-flex xs12>
                  <h3 class="home-section-title reservations green-on-white">
                    <span>The Destination Experts</span>
                  </h3>
                  <p>Highly specialised consultants with in-depth New Zealand and Australian knowledge.</p>
                </v-flex>
                <v-flex xs12>
                  <h3 class="home-section-title reservations green-on-white">
                    <span>Assistance on the Ground</span>
                  </h3>
                  <p>Client assistance and support through fully-owned offices in Australia and in New Zealand.</p>
                </v-flex>
                <v-flex xs12>
                  <h3 class="home-section-title reservations green-on-white">
                    <span>Commitment & Attention to Detail</span>
                  </h3>
                  <p
                    class="mb-0"
                  >Our expert team go the extra mile to ensure your clients have the best vacation experiences.</p>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>

    <!--
    <v-layout row class="home-section-product">
      <v-flex>
        <v-card dark :color="coalBlack" flat tile>
          <v-img
            src="https://cdn.anzcro.com.au/wp-content/uploads/2019/11/home-ruapehu-1920w.jpg"
            gradient="to bottom, rgba(48,48,47,.9), rgba(22,22,22,0.95)"
            class="home-product-bg"
          >
            <v-container text-xs-center fill-height>
              <v-layout row align-center>
                <v-flex xs12 sm10 md12 py-5 offset-sm1 offset-md0>
                  <h3 class="home-section-title white-on-dark">
                    <span>Our Product Range</span>
                  </h3>
                  <v-layout row wrap>
                    <v-flex xs6 sm4 md3 text-xs-center offset-md32 class="product-range-item">
                      <img
                        height="60"
                        :src="require('@/assets/img/icons/display/B01_Accommodation.svg')"
                      />
                      <span class="subheading d-block mt-2">Accommodation</span>
                    </v-flex>
                    <v-flex xs6 sm4 md3 text-xs-center class="product-range-item">
                      <img
                        height="60"
                        :src="require('@/assets/img/icons/display/B02_FIT_Packages.svg')"
                      />
                      <span class="subheading d-block mt-2">FIT Packages</span>
                    </v-flex>
                    <v-flex xs6 sm4 md3 text-xs-center class="product-range-item">
                      <img
                        height="60"
                        :src="require('@/assets/img/icons/display/B03_Ski_Holidays.svg')"
                      />
                      <span class="subheading d-block mt-2">Ski Holidays</span>
                    </v-flex>
                    <v-flex xs6 sm4 md3 text-xs-center class="product-range-item">
                      <img
                        height="60"
                        :src="require('@/assets/img/icons/display/B04_Rental_Vehicles.svg')"
                      />
                      <span class="subheading d-block mt-2">
                        Rental Cars
                        <br />& Campers
                      </span>
                    </v-flex>
                    <v-flex xs6 sm4 md3 text-xs-center class="product-range-item">
                      <img
                        height="60"
                        :src="require('@/assets/img/icons/display/B05_DayTours.svg')"
                      />
                      <span class="subheading d-block mt-2">
                        Day Tours &
                        <br />Activities
                      </span>
                    </v-flex>
                    <v-flex xs6 sm4 md3 text-xs-center class="product-range-item">
                      <img
                        height="60"
                        :src="require('@/assets/img/icons/display/B06_MultiDayScenicTours.svg')"
                      />
                      <span class="subheading d-block mt-2">
                        Multiday
                        <br />Scenic Tours
                      </span>
                    </v-flex>
                    <v-flex xs6 sm4 md3 text-xs-center class="product-range-item">
                      <img
                        height="60"
                        :src="require('@/assets/img/icons/display/B07_Transfers.svg')"
                      />
                      <span class="subheading d-block mt-2">
                        Transfers &
                        <br />Intercity Travel
                      </span>
                    </v-flex>
                    <v-flex xs6 sm4 md3 offset-md32 text-xs-center class="product-range-item">
                      <img
                        height="60"
                        :src="require('@/assets/img/icons/display/B08_Walks_and_Hikes.svg')"
                      />
                      <span class="subheading d-block mt-2">Walks & Hikes</span>
                    </v-flex>
                    <v-flex xs6 sm4 md3 text-xs-center class="product-range-item">
                      <img
                        height="60"
                        :src="require('@/assets/img/icons/display/B09_Cruise_Shore_Excursions.svg')"
                      />
                      <span class="subheading d-block mt-2">
                        Cruise Shore
                        <br />Excursions
                      </span>
                    </v-flex>
                    <v-flex
                      xs6
                      sm4
                      md3
                      offset-sm4
                      offset-md0
                      text-xs-center
                      class="product-range-item"
                    >
                      <img
                        height="60"
                        :src="require('@/assets/img/icons/display/B10_Rail_Journeys.svg')"
                      />
                      <span class="subheading d-block mt-2">Rail Journeys</span>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-container>
          </v-img>
        </v-card>
      </v-flex>
    </v-layout>
    -->

    <!--
    <v-layout row class="home-section-testimonials">
      <v-flex>
        <v-card :color="lightGrey" flat tile class="card-wrapper">
          <v-container text-xs-center>
            <v-layout row>
              <v-flex xs12 py-5>
                <h3 class="home-section-title green-on-white">
                  <span>Words from our partners</span>
                </h3>
                <v-card flat :color="lightGrey">
                  <v-window v-model="testimonial" height="auto">
                    <v-window-item key="card-1">
                      <v-card :color="lightGrey" flat height="300">
                        <v-layout align-center justify-center fill-height>
                          <v-flex>
                            <span class="headline d-block font-weight-medium hp-testimonial">
                              <q>My clients had a fantastic time on their ANZCRO holiday and everything went perfectly, thank you! As a matter of fact they have referred another client for their proposed trip to New Zealand and I have put through a quote request through for this.</q>
                            </span>
                            <v-avatar :color="lightGrey" size="40" class="mt-4 mb-2">
                              <img
                                src="https://cdn.anzcro.com.au/wp-content/uploads/2019/11/travel-counsellors-avatar.png"
                                alt="Travel Counsellors Logo"
                              />
                            </v-avatar>
                            <span class="d-block mb-5">Rebecca, Travel Counsellors</span>
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-window-item>
                    <v-window-item key="card-2">
                      <v-card :color="lightGrey" flat height="300">
                        <v-layout align-center justify-center fill-height>
                          <v-flex>
                            <span class="headline d-block font-weight-medium hp-testimonial">
                              <q>Thanks for all your help with my booking – the whole ANZCRO reservation team has really gone above and beyond to help me and I’m super appreciative.</q>
                            </span>
                            <v-avatar :color="lightGrey" size="40" class="mt-4 mb-2">
                              <img
                                src="https://cdn.anzcro.com.au/wp-content/uploads/2019/11/raa-avatar.png"
                                alt="RAA Logo"
                              />
                            </v-avatar>
                            <span class="d-block mb-5">Michael, RAA Oaklands Park</span>
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-window-item>
                  </v-window>
                  <v-card-actions class="justify-space-between">
                    <v-btn flat @click="prev">
                      <v-icon>chevron_left</v-icon>
                    </v-btn>
                    <v-item-group v-model="testimonial" class="text-xs-center" mandatory>
                      <v-item v-for="n in length" :key="`btn-${n}`">
                        <v-btn
                          slot-scope="{ active, toggle }"
                          :input-value="active"
                          icon
                          @click="toggle"
                        >
                          <v-icon size="10">lens</v-icon>
                        </v-btn>
                      </v-item>
                    </v-item-group>
                    <v-btn flat @click="next">
                      <v-icon>chevron_right</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
    -->


    <v-layout row class="home-section-suppliers">
      <v-flex>
        <v-card flat tile :color="offWhite">
          <v-container text-xs-center>
            <v-layout row>
              <v-flex xs12 py-5>
                <h3 class="home-section-title green-on-white mb-5">
                  <span>Some of our great suppliers</span>
                </h3>
                <v-layout row wrap mb-4>
                  <v-flex xs6 sm4 md3 lg2 py-4>
                    <img width="100" class="mx-auto" :src="require('@/assets/img/logos/thl.png')" />
                  </v-flex>
                  <v-flex xs6 sm4 md3 lg2 py-4>
                    <img width="100" class="mx-auto" src="https://cdn.anzcro.com.au/wp-content/uploads/2023/08/hobbiton.png" />
                  </v-flex>
                  <v-flex xs6 sm4 md3 lg2 py-4>
                    <img width="100" class="mx-auto" :src="require('@/assets/img/logos/budget.svg')" />
                  </v-flex>
                  <v-flex xs6 sm4 md3 lg2 py-4>
                    <img width="100" class="mx-auto" src="https://cdn.anzcro.com.au/wp-content/uploads/2023/08/journey-beyond.png" />
                  </v-flex>
                  <v-flex xs6 sm4 md3 lg2 py-4>
                    <img width="100" class="mx-auto" src="https://cdn.anzcro.com.au/wp-content/uploads/2020/08/ihg.png" />
                  </v-flex>
                  <v-flex xs6 sm4 md3 lg2 py-4>
                    <img width="100" class="mx-auto" :src="require('@/assets/img/logos/accor-hotels.svg')" />
                  </v-flex>
                  <v-flex xs6 sm4 md3 lg2 py-4>
                    <img width="100" class="mx-auto" :src="require('@/assets/img/logos/baillie-lodges.svg')" />
                  </v-flex>
                  <v-flex xs6 sm4 md3 lg2 py-4>
                    <img width="100" class="mx-auto" :src="require('@/assets/img/logos/hertz.svg')" />
                  </v-flex>
                  <v-flex xs6 sm4 md3 lg2 py-4>
                    <img width="100" class="mx-auto" :src="require('@/assets/img/logos/sudima.svg')" />
                  </v-flex>
                  <v-flex xs6 sm4 md3 lg2 py-4>
                    <img width="100" class="mx-auto" :src="require('@/assets/img/logos/the-great-journeys-of-new-zealand.svg')" />
                  </v-flex>
                  <v-flex xs6 sm4 md3 lg2 py-4>
                    <img width="100" class="mx-auto" src="https://cdn.anzcro.com.au/wp-content/uploads/2020/08/voyages.png" />
                  </v-flex>
                  <v-flex xs6 sm4 md3 lg2 py-4>
                    <img width="100" class="mx-auto" src="https://cdn.anzcro.com.au/wp-content/uploads/2020/08/mantra.png" />
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>

    <!--
    <v-layout row class="home-section-cards">
      <v-flex>
        <v-card dark :color="coalBlack" flat tile class="transparent">
          <v-container text-xs-center fill-height>
            <v-layout row align-center>
              <v-flex xs12 sm10 md12 py-5 offset-sm1 offset-md0>
                <h4 class="lightGrey title mb-3">
                  Haven't Got Your
                  <span class="white--text">FREE</span> Account Yet?
                </h4>
                <h3
                  class="white--text display-1 font-weight-bold mb-4"
                >Register today & discover a new World of NZ Travel</h3>
                <v-btn
                  large
                  class="ml-0 mt-4 mb-2 elevation-0"
                  dark
                  :color="kiwiGreen"
                  to="/register/"
                >Register Now</v-btn>
                <v-btn
                  large
                  class="mt-4 mb-2 elevation-0 px-4"
                  color="white"
                  outline
                  to="/login/"
                >Login</v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>-->
  </v-container>
</template>

<script>
export default {
  data: () => ({
    coalBlack: '#30302F',
    kiwiGreen: '#65b32e',
    length: 2,
    testimonial: 0,
    heros: [
      {
        source:
          'https://cdn.anzcro.com.au/wp-content/uploads/2019/11/hero-rulers-1920x695.png',
        caption: 'Te Puia Maori Experience - Rotorua',
      },
      {
        source:
          'https://cdn.anzcro.com.au/wp-content/uploads/2019/11/hero-rulers-1920x695.png',
        caption: 'Waitomo Glowworm Caves',
      },
      {
        source:
          'https://cdn.anzcro.com.au/wp-content/uploads/2019/11/hero-rulers-1920x695.png',
        caption: 'Ski Holiday - Cardrona Alpine Resort',
      },
      {
        source:
          'https://cdn.anzcro.com.au/wp-content/uploads/2019/11/hero-rulers-1920x695.png',
        caption: 'The Redwoods - Rotorua',
      },
    ],
  }),
  computed: {
    randomHero() {
      return this.heros[Math.floor(Math.random() * this.heros.length)];
    },
  },
  mounted: function() {
    const el = document.scrollingElement || document.documentElement;
    el.scrollTop = 0;
  },
  methods: {
    next() {
      this.testimonial = this.testimonial + 1 === this.length ? 0 : this.testimonial + 1;
    },
    prev() {
      this.testimonial = this.testimonial - 1 < 0 ? this.length - 1 : this.testimonial - 1;
    },
  },
};
</script>

<style>
.home-hero,
.home-product-bg,
.home-cta-bg {
  height: auto;
}

.home-hero {
  position: relative;
}

.home-hero .hero-caption {
  position: absolute;
  display: block;
  background: rgb(48, 48, 47);
  color: #acacac;
  position: absolute;
  bottom: 18px;
  right: 18px;
  padding: 6px 9px;
}

.home-hero .v-image__image{
  background-position: center center !important;
}

.home-hero-cta{
  display:-webkit-box !important;
  -webkit-box-pack:center !important;
  -webkit-box-align:center !important;
}

.home-hero-cta,
.home-hero-cta .fill-height-fix-ios {
  min-height: 82vh;
  min-height: calc(100vh - 200px);
}

.home-hero-cta h1 {
  line-height: 1.2 !important;
}


.home-hero-cta h3{
  max-width:520px;
  line-height: 1.2 !important;
  margin-left:auto;
  margin-right:auto;
}

.hero-card {
  border-radius: 18px;
  margin: 9px 0;
  /*border: solid 1px #222222 !important;*/
}

.home-section-cards {
  background-color: #30302f;
  background-image: url('~@/assets/img/bg_tile_grey_waves_2.png');
  background-repeat: repeat;
  background-size: 50px 51px;
  padding: 16px 0;
}

.container.grid-list-lg .layout .flex.connect-icon-item {
  padding: 8px 24px;
}

.home-section-why {
  background-color: #65b32e;
  background-image: url('~@/assets/img/bg_tile_green_waves.png');
  background-repeat: repeat;
  background-size: 50px 51px;
}

.home-section-cards .v-card.transparent,
.home-section-why .v-card.transparent {
  background-color: transparent !important;
}

.home-section-title {
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 32px;
}

.home-section-title.reservations {
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 6px;
}

.home-section-title.green-on-white {
  color: #65b32e;
}

.home-section-title.white-on-green {
  color: #ffffff;
}

.about-quote {
  line-height: 28px !important;
  margin-bottom: 32px;
}

.home-reservations-content {
  padding: 0 24px;
}

.home-reservations-content p {
  margin: 0 0 32px;
}

.product-range-item {
  margin: 32px 0;
}

.headline.hp-testimonial {
  font-size: 16px !important;
  line-height: 1.5 !important;
}

.hero-home-slider .v-carousel__controls {
  background: none;
}

@media (min-width: 600px) {
  .home-hero {
    height: 100vw;
    max-height:695px;
  }

  .home-hero .v-image__image{
    background-position: center center !important;
  }

  .home-hero-cta,
  .home-hero-cta .fill-height-fix-ios {
    min-height: auto;
  }

  .home-hero-cta .display-1 {
    font-size: 54px !important;
    line-height: 1.2 !important;
  }

  .hero-card {
    margin: 9px;
  }

  .home-section-about p,
  .home-reservations-content p,
  .home-section-why .subheading {
    font-size: 16px;
    line-height: 28px;
  }

  .home-section-title {
    position: relative;
    vertical-align: middle;
  }

  .home-section-title span {
    display: inline-block;
    vertical-align: middle;
    padding: 0 12px;
  }

  .home-section-title.reservations span {
    padding: 0 12px 0 0;
  }

  .home-section-title:before,
  .home-section-title:after {
    content: '';
    display: inline-block;
    width: 36px;
    height: 2px;
    background: #65b32e;
    opacity: 0.75;
  }

  .home-section-title.white-on-green:before,
  .home-section-title.white-on-green:after {
    background: #ffffff;
  }

  .home-section-title.reservations:before {
    display: none;
  }

  .headline.hp-testimonial {
    font-size: 24px !important;
  }

  .container.grid-list-lg .layout .flex.connect-icon-item {
    padding: 24px;
  }

  .connect-headline span {
    display: block;
  }
}

@media (min-width: 960px) {

  .home-hero {
    height: 60vw;
    max-height:695px;
  }
  .home-hero .v-image__image {
    background-position: center center !important;
  }
  .home-product-bg {
    max-height: 900px;
  }
  .home-cta-bg {
    max-height: 500px;
  }
  .hero-card {
    margin: 9px 0;
  }
  .about-quote {
    font-size: 32px !important;
    line-height: 44px !important;
  }

  .home-reservations-content {
    padding-left: 48px;
  }

  .home-reservations-content p {
    max-width: 400px;
  }

  .home-section-product .flex.offset-md32 {
    margin-left: 12.5%;
  }

  .product-range-item {
    margin: 40px 0;
  }

  .home-section-about .v-card,
  .home-section-why .v-card,
  .home-section-suppliers .v-card,
  .home-section-testimonials .v-card.card-wrapper {
    padding: 12px 0;
  }

  .hero-home-slider {
    padding: 0;
    position: relative;
  }

  .hero-connect-wrapper .v-card {
    height: 100%;
  }

  .container.grid-list-lg .layout .flex.connect-icon-item {
    padding: 24px 8px;
  }

  .connect-headline span {
    display: inline;
  }

  .home-hero-cta h3{
    max-width:600px;
    margin-left:0;
    margin-right:0;
  }
}

@media (min-width: 1264px) {

  .home-hero {
    height: 40vw;
    max-height:695px;
  }

}

@media (min-width: 1440px) {
  .home-hero-cta .display-1 {
    font-size: 66px !important;
  }
  .home-hero-cta .title {
    font-size: 24px !important;
  }
}
</style>